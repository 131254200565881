import Vue from 'vue'
/* 加载element ui相关 */
import Element from 'element-ui'
import './element-variables.scss'
/* 加载国际化实例 */
import i18n from './lang/index'
/* 加载框架内组件 */
import App from './App.vue'
import router from './router'
import store from './store'
/* 加载应用三方组件 */
import Fragment from 'vue-fragment'
import moment from 'moment'
import lodash from 'lodash'
import * as echarts from 'echarts'
/* 加载自定义公共类 */
import common from '@/utils/common'
import './style/main.scss'

Vue.use(Element, {
	size: 'medium',
	i18n: (key, value) => i18n.t(key, value) 
})

Vue.use(Fragment.Plugin)

Vue.prototype.$moment = moment
Vue.prototype.$lodash = lodash
Vue.prototype.$echarts = echarts

Vue.prototype.$common = common

Vue.config.productionTip = false

new Vue({
	i18n,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
