import common from './common.js'
import apiCodes from './apiCodes.js'
import enums from './enums.js'
import routers from './routers.js'

export default {
	/* 系统公共的国际化key */
	common: common,
	/* 路由名称国际化key[涉及面包屑] */
	routers: routers,
	/* 接口验证错误码国际化key[非页面] */
	apiCodes: apiCodes,
	/* 全局数据枚举国际化 */
	enums: enums
}