/* 公共接口的调用 */
import Api from '@/utils/api'

/* 基础服务代理 */
const Base = '/base_proxy'

/* B2B网关支付登录页用-获取授权用CODE */
export const b2bCodeGen = () => {
	return Api.get(`${Base}/auth/token/get-b2b-code?t=` + new Date().getTime())
}

/* B2B网关支付登录页用-轮询验证是否已授权 */
export const pollingChkB2BCode = (code) => {	
	return Api.post(`${Base}/auth/token/polling-chk-b2b`,{
		xid: code
	})
}

/* B2B网关支付登录页用-清除授权CODE */
export const clearB2BCode = (code) => {	
	return Api.post(`${Base}/auth/token/clear-b2b-code/${code}`,{})
}

/* B2B网关支付APP端切换其他支付方式/B2B网关页放弃支付用-释放对公银行支付的锁 */
export const releaseOrdering = (goodsType,orderNos) => {	
	return Api.post(`${Base}/auth/token/release-b2b-order-ing`,{
		goodsType: goodsType,
		orderNos: orderNos
	})
}

/* 生成预支付信息 */
export const genPrePayInfo = (goodsType,orderNos,orderUserId) => {	
	return Api.post(`${Base}/base/payment/pre-pay`,{
		clientType: 'pc',
		orderPay: {
			goodsType: goodsType,
			orderNos: orderNos,
			orderUserId: orderUserId
		},
		payType: '5',
		payWay: '1'		
	})
}

/* 根据订单号列表获取订单详情列表（多个订单已英文逗号分割） */
export const getByOrderNos = (goodsType,orderNos,orderUserId) => {
	return Api.post(`${Base}/base/payment/b2b/list-order`,{
		goodsType: goodsType,
		orderNos: orderNos,
		orderUserId: orderUserId
	})
}
