/* 通用公共方法定义 */
/* 1.格式化字符串 formatString("{0}-{1}",["你好", "世界"]) */
export const formatString = (formatted, args) => {
	for (let i = 0; i < args.length; i++) {
		let regexp = new RegExp('\\{' + i + '\\}', 'gi')
		formatted = formatted.replace(regexp, args[i])
	}
	return formatted
}

/* 设定全局表格头部单元格样式 */
export const headerCellStyle = ({row, column, rowIndex, columnIndex}) => {
	return {
		background:'#f4f4f5',
		color:'#606266',
	}
}

export default {
	/* 格式化字符串 */
	formatString(formatted, args) {
		return formatString(formatted, args)
	},
	/* 设定全局表格头部单元格样式 */
	headerCellStyle({row, column, rowIndex, columnIndex}) {		
		return headerCellStyle({row, column, rowIndex, columnIndex})
	},
	/* 设定全局分页公共信息 */
	pagingOptions: {
		size: 10, /* 分页每页默认条数 */
		sizes: [10, 20, 30, 40, 50], /* 分页下拉项 */
		layout: 'slot, total, ->, prev, pager, next, sizes, jumper' /* 分页布局 */
	}
}