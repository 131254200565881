/* 接口验证错误码国际化key[非页面] */
export default {
	'30103': '订单信息不存在',
	'30126': '订单状态已更新，请确认后重新操作',
	'30128': '当前订单还未到支付尾款时间哦~',
	'30129': '线下支付订单不可以发起线上支付~',
	'30113': '当前订单非待支付单',
	'10025': '暂无门店集采权限',
	'B2B001': '当前订单已经存在公网银支付行为',
	/* 旅游线路向导 */
	'60013': '订单信息不存在',
	'60021': '非待支付单，无法完成支付',	
	
	/* 养老公寓 */
	'82000': '订单信息不存在',
	'82006': '非待支付单，无法完成支付',
	
	/* 医疗 */
	'MC20001': '订单信息不存在',
	'MC20007': '非待支付单，无法完成支付',
	
	/* 家政 */
	'HA20001': '订单信息不存在',
	'HA20007': '非待支付单，无法完成支付',
	
	/* 养老课堂 */
	'MOOC_11011': '订单信息不存在',
	'MOOC_11016': '非待支付单，无法完成支付',
	
	'P001': '当前订单有人正在支付，请稍后重试'
}