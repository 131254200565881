<template>
	<div class="pay-wrapper full-height" v-if="order">
		<div class="nav">
			<div class="logo">
				<img :src="require('@/assets/logo.png')"/>
				<img style="margin-left: 12px;" :src="require('@/assets/pingan.png')"/>
			</div>
			<div class="user-info">
				<div class="phone">{{user.phone}}</div>
				<div class="nickname">
					<img :src="user.avatar?`${imgUrl}${user.avatar}`:require('@/assets/user.png')"/>
					<span class="over-content">{{user.nickname}}</span>
				</div>
			</div>
		</div>
		<div class="header">
			<div class="title" v-if="status == '1'">
				订单支付成功，请在偶爸app查看订单详情！
			</div>
			<div class="title" v-if="status == '0'">
				订单支付失败，请确认您的网银账户交易明细后重新尝试！
			</div>
			<div class="title" v-if="status == '-1'">
				订单支付失败，可能是由于以下原因:
			</div>
			<div class="tradeNo" v-if="status == '1'">
				<label>交易号:</label>
				<span style="color: #EB4336;font-weight: 600;">{{tradeNo}}</span>
			</div>
			<div class="orderNo" v-if="status !== '-1'">
				<span>
					<label>订单号:</label>
					<span style="color: #EB4336;font-weight: 600;">{{orderNo}}</span>
				</span>
				<span>
					订单金额:
					<span style="color: #EB4336;font-weight: 600;font-size: 20px;">￥{{(order.payAmount/100).toFixed(2)}}</span>
				</span>
			</div>
			<div class="orderNo" v-else>
				<span style="color: #EB4336;font-weight: 600;">{{$t('apiCodes')[tradeNo]}}</span>
			</div>
		</div>
		<div class="content" :style="status == '1' ? 'min-height: calc(99% - 395px);':'min-height: calc(99% - 354px);'">
			<div class="info" v-if="status == '1'">
				<img :src="require('@/assets/pay-success.png')"/>
				<div class="desc">订单支付成功</div>
				<el-button type="primary" @click="goAuth()">
					支付其他订单
				</el-button>
			</div>
			<div class="info" v-else>
				<img :src="require('@/assets/pay-error.png')"/>
				<div class="desc">订单交易失败，请尝试重新支付！</div>
				<el-button type="primary" @click="goAuth()">
					支付其他订单
				</el-button>
			</div>
		</div>
		<div class="footer">
			<div class="icp">
				<a 	target="_blank" 
					href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050802011267">
					<img :src="require('@/assets/beian.d0289dc0.png')"/>
					<span style="margin: 0px 0px 0px 5px; color: #000000;">苏公网安备 32050802011267号</span>
				</a>
				<span style="margin-left: 40px;">增值电信业务经营许可证：苏B2-20200366 | 苏ICP备17011521号-1 | 营业执照</span>
			</div>
			<div style="margin-top: 8px">Copyright © 2019-现在 obawang.com 版权所有</div>
		</div>
	</div>
</template>

<script>
	import StorageUtils from '@/utils/storage'
	import {getByOrderNos,genPrePayInfo} from '@/request/base'
	
	export default {
		name: 'pay-result',
		data() {
			return {
				loading: false,
				imgUrl: process.env.VUE_APP_IMG_SHOW_URL, // 图片显示前缀域名
				user: {},
				order: null,
				status: '1',
				orderNo: '',
				tradeNo: ''
			}
		},
		created() {
			let status = this.$route.params.status
			this.status = status
			this.orderNo = this.$route.params.orderNo
			this.tradeNo = this.$route.params.tradeNo
			if(status == '1' && this.orderNo != '' && this.tradeNo != '') {
				this.renderBasic()
			} else if(status == '0' && this.orderNo != '' && this.tradeNo != '') {
				this.renderBasic()
			} else if(status == '-1' && this.tradeNo != '') {
				this.renderBasic()
			} else {
				this.$message.error('不合法的请求调用，请联系管理员')
				this.goAuth()
			}
		},
		methods: {
			renderBasic() {
				let uif = StorageUtils.getUserInfo();
				this.user = uif
				getByOrderNos(uif.goodsType,uif.orderNos,uif.orderUserId).then(response => {
					this.order = response.data.data
				}).catch(errorMessage => {
					this.$notify.error({
						title: '错误',
						message: errorMessage
					});
				})
			},
			goAuth() {
				this.$router.push({path: '/auth'})
			}
		}
	}
</script>

<style scoped lang="scss">
	.pay-wrapper {
		overflow-y: scroll;
		background-color: #F5F7FA;
		opacity: 0.9;
		
		.nav {
			height: 100px;
			background-color: #FFFFFF;
			box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
			z-index: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0px 120px;
			position: relative;
			
			.logo {
				display: flex;
				align-items: center;
			}
			
			.user-info {
				width: 104px;
				
				.phone {
					font-size: 14px;
					color: #656565;
					text-align: end;
				}
				
				.nickname {
					margin-top: 6px;
					display: inline-flex;
					align-items: center;
					
					img {
						margin-right: 10px;
						width: 30px;
						height: 30px;
					}
					
					span {
						font-size: 16px;
						color: #333333;
					}
				}
			}
		}
		
		.header {
			background-color: #FFFFFF;
			padding: 0px 120px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			
			.title {
				font-size: 20px;
				color: #4D4D4D;
				font-weight: 600;
				margin-top: 30px;
			}
			
			.tradeNo {
				font-size: 18px;
				color: #4D4D4D;
				margin-top: 17px;
			}
			
			.orderNo {
				margin-top: 14px;
				margin-bottom: 31px;
				font-size: 18px;
				color: #4D4D4D;
				
				display: inline-flex;
				justify-content: space-between;
				align-items: center;
			}
			
			label {
				margin-right: 10px;
			}
		}
		
		.content {
			background-color: #FFFFFF;
			margin-top: 20px;
			padding: 0px 120px;
			font-size: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
			
			.info {
				display: flex;
				flex-direction: column;
				align-items: center;
				
				.desc {
					margin-top: 30px;
					margin-bottom: 50px;
					font-size: 24px;
					color: #4D4D4D;
					font-weight: 600;
				}
			}
			
		}
		
		.footer {
			height: 105px;
			text-align: center;
			
			.icp {
				margin-top: 30px;
				display: inline-flex;
				
				a {
					text-decoration: none;
					display: inline-flex;
				}
			}
		}
	}
</style>
