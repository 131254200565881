<template>
	<div class="pay-wrapper full-height" v-if="order">
		<div class="nav">
			<div class="logo">
				<img :src="require('@/assets/logo.png')"/>
				<img style="margin-left: 12px;" :src="require('@/assets/pingan.png')"/>
			</div>
			<div class="user-info">
				<div class="phone">{{user.phone}}</div>
				<div class="nickname">
					<img :src="user.avatar?`${imgUrl}${user.avatar}`:require('@/assets/user.png')"/>
					<span class="over-content">{{user.nickname}}</span>
				</div>
			</div>
		</div>
		<!-- 倒计时超时取消以及正常倒计时场景 -->
		<!-- 订单已经取消的情况 -->
		<div class="header" v-if="cancel">
			<div class="title" style="color: #EB4336;">
				订单已被手动取消
			</div>
			<div class="process">
				<span class="timer" >
					关联订单被APP端手动取消, 当前支付交易已失效！
				</span>
				<span>
					订单金额:
					<span style="color: #EB4336;font-weight: 600;font-size: 20px;">￥{{(order.payAmount/100).toFixed(2)}}</span>
				</span>
			</div>	
		</div>
		<!-- 订单未被取消情况 -->
		<div class="header" v-else>
			<!-- 未超时取消 -->
			<div class="title"  v-if="count != '-'">
				订单提交成功, 请尽快完成付款!
			</div>
			<!-- 超时取消 -->
			<div class="title" v-else style="color: #EB4336;">
				订单支付超时, 系统已自动取消
			</div>
			<div class="process">
				<!-- 超时取消 -->
				<span class="timer" v-if="count == '-'">
					<img :src="require('@/assets/timer.png')"/>
					订单因支付超时已自动取消, 返回app检查订单状态
				</span>
				<!-- 未超时取消但倒计时 -->
				<span class="timer" v-else-if="count != ''">
					<img :src="require('@/assets/timer.png')"/>
					请您在
					<span class="count">{{count}}</span>
					内完成支付, 否则订单会被自动取消
				</span>
				<span  class="timer" v-else>
					<!-- 默认或集采描述 -->
					您正在使用对公网银的渠道支付{{order.orderType == '1'? '预售':'集采'}}订单
				</span>
				<span>
					订单金额:
					<span style="color: #EB4336;font-weight: 600;font-size: 20px;">￥{{(order.payAmount/100).toFixed(2)}}</span>
				</span>
			</div>
		</div>
		<div class="content">
			<div class="title">订单明细</div>
			<div class="list">
				<div class="detail" v-for="(store,idx) in order.goodsByStoreList" :key="idx">
					<div class="row">
						<span class="label">店铺名称：</span>
						<span class="item">{{store.storeName}}</span>
					</div>
					<div class="row">
						<span class="label">商品/服务：</span>
						<div class="product">
							<div class="item" v-for="(goods,sidx) in store.goodsList" :key="sidx">
								<img style="width: 86px;height: 86px;border-radius: 4px;" :src="`${imgUrl}${goods.goodsCover}`"/>
								<div class="info">
									<div class="name over-content">{{goods.goodsTitle}}</div>
									<div class="props">商品规格：{{goods.goodsSpec}}</div>
									<div class="quantity">x{{goods.goodsCnt}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="spliter">
				<el-button type="info" @click="cancelPay">取消交易</el-button>
				<el-button :loading="loading" :type="btnDisabled?'info':'primary'" size="large" @click="submitPay()" :disabled="btnDisabled">
					<span v-if="btnDisabled">已取消</span>
					<span v-else>去支付</span>
				</el-button>
			</div>
		</div>
		<div class="footer">
			<div class="icp">
				<a 	target="_blank" 
					href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050802011267">
					<img :src="require('@/assets/beian.d0289dc0.png')"/>
					<span style="margin: 0px 0px 0px 5px; color: #000000;">苏公网安备 32050802011267号</span>
				</a>
				<span style="margin-left: 40px;">增值电信业务经营许可证：苏B2-20200366 | 苏ICP备17011521号-1 | 营业执照</span>
			</div>
			<div style="margin-top: 8px">Copyright © 2019-现在 obawang.com 版权所有</div>
		</div>
	</div>
</template>

<script>
	import StorageUtils from '@/utils/storage'
	import {getByOrderNos,genPrePayInfo,releaseOrdering} from '@/request/base'
	
	export default {
		name: 'pay-detail',
		data() {
			return {
				loading: false,
				imgUrl: process.env.VUE_APP_IMG_SHOW_URL, // 图片显示前缀域名
				user: {},
				order: null,
				timer: null, /* 校验有效性计时器 */
				count: '',/* 倒计时显示  '' 初始状态，'-' 超时取消*/
				btnDisabled: false,
				cancel: false, /* 是否订单存在取消 */
				token: StorageUtils.getToken()
			}
		},
		beforeRouteLeave(to,from,next) {
			if(this.timer) {
				window.clearInterval(this.timer)
			}
			next()
		},
		created() {
			let uif = StorageUtils.getUserInfo();
			this.user = uif
			getByOrderNos(uif.goodsType,uif.orderNos,uif.orderUserId).then(response => {
				this.order = response.data.data
				this.order.goodsByStoreList.forEach((od,idx) => {
					if(od.orderStatus == '2') {
						this.cancel = true
					}
				})
				if(!this.cancel) {
					if(this.order.responseEndTime) {
						/* 非集采订单，设计计时器 */
						let resp = this.$moment(this.order.responseEndTime).format('YYYY/MM/DD HH:mm:ss')
						let _timer = window.setInterval(() => {
							/* 判定存在会话在多TAB打开被覆盖, 保证会话唯一性 */
							if (!StorageUtils.getToken() || this.token != StorageUtils.getToken()) {
								window.clearInterval(_timer)
								
								const h = this.$createElement;
								this.$msgbox({
									center: true,
									showClose: false,
									closeOnClickModal: false,
									title: '交易会话异常',
									message: h('p',{style: 'color: #EB4336'},'存在试图发起的新交易授权，当前会话已失效!'),
									confirmButtonText: '知道了'
								}).then(action => {
									window.opener = null;
									window.open("about:blank", "_top").close()
								});
								
							} else {
								let diff = this.difference(this.$moment().format('YYYY/MM/DD HH:mm:ss'),resp)
								if(diff.timestamp > 0) {
									let diffStr = ''
									if(diff.day != '0') {
										diffStr += diff.day + '天'
									}
									if(diff.hour != '0') {
										diffStr += diff.hour + '时'
									}
									if(diff.minute != '0') {
										diffStr += diff.minute + '分'
									}
									if(diff.second != '0') {
										diffStr += diff.second + '秒'
									}
									this.count = diffStr
								} else {
									window.clearInterval(_timer)
									/* 标记已超时取消 */
									this.count = '-'
									this.btnDisabled = true
									
									const h = this.$createElement;
									this.$msgbox({
										center: true,
										showClose: false,
										closeOnClickModal: false,
										title: '订单异常',
										message: h('p',{style: 'color: #EB4336'},'订单因支付超时已自动取消, 返回app检查订单状态'),
										confirmButtonText: '知道了'
									}).then(action => {
										this.goAuth()
									});
								}
							}
						}, 1000)
						this.timer = _timer
					} else {
						/* 集采订单 */
					}
				} else {
					const h = this.$createElement;
					this.$msgbox({
						center: true,
						showClose: false,
						closeOnClickModal: false,
						title: '订单异常',
						message: h('p',{style: 'color: #EB4336'},'关联订单存在APP端手动取消，无法进行正常B2B支付!'),
						confirmButtonText: '知道了'
					}).then(action => {
						this.goAuth()
					});
				}
			}).catch(errorMessage => {
				this.$notify.error({
					title: '错误',
					message: errorMessage
				});
			})
		},
		methods: {
			difference(beginTime, endTime) {
				let dateBegin = new Date(beginTime)
				let dateEnd = new Date(endTime)
				let dateDiff = dateEnd.getTime() - dateBegin.getTime() //时间差的毫秒数
				let dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)) //计算出相差天数
				let leave1 = dateDiff % (24 * 3600 * 1000)    //计算天数后剩余的毫秒数
				let hours = Math.floor(leave1 / (3600 * 1000)) //计算出小时数
				//计算相差分钟数
				let leave2 = leave1 % (3600 * 1000)   //计算小时数后剩余的毫秒数
				let minutes = Math.floor(leave2 / (60 * 1000)) //计算相差分钟数
				//计算相差秒数
				let leave3 = leave2 % (60 * 1000)    //计算分钟数后剩余的毫秒数
				let seconds = Math.round(leave3 / 1000)
				return {
					timestamp: dateDiff,
					day: dayDiff,
					hour: hours,
					minute: minutes,
					second: seconds
				}
			},
			submitPay() {
				this.loading = true
				let uif = StorageUtils.getUserInfo();
				let params = {
					"clientType": 'pc',
					"payType": '5',
					"payWay": '1',
					"orderPay.goodsType": uif.goodsType,
					"orderPay.orderNos": uif.orderNos,
					"orderPay.orderUserId": uif.orderUserId
				}
				this.genTempForm(params)
				/* window.close() */
			},
			genTempForm(params) {
				//post请求以form形式提交
				let tempForm = document.createElement('form')
				tempForm.action = process.env.VUE_APP_B2B_GATE_WAY_URL+ '?Authorization=' + StorageUtils.getToken() + '&client=B2BP'
				tempForm.method = 'post'
				/* tempForm.target = '_blank' */
				tempForm.style.display = 'none'
				for (var x in params) {
					var opt = document.createElement('input')
					opt.name = x
					opt.value = params[x]
					tempForm.appendChild(opt)
				}
				document.body.appendChild(tempForm)
				tempForm.submit()
				/* StorageUtils.deleteToken();
				StorageUtils.clearUserInfo(); */
			},
			cancelPay() {
				//调用取消当前授权交易
				let uif = StorageUtils.getUserInfo();
				releaseOrdering(uif.goodsType,uif.orderNos).then(response => {
					this.goAuth()
				})
			},
			goAuth() {
				StorageUtils.deleteToken();
				StorageUtils.clearUserInfo();
				if(this.timer) {
					window.clearInterval(this.timer)
				}
				this.$router.push({path: '/auth'})
			}
		}
	}
</script>

<style scoped lang="scss">
	.pay-wrapper {
		overflow-y: scroll;
		background-color: #F5F7FA;
		opacity: 0.9;
		
		.nav {
			height: 100px;
			background-color: #FFFFFF;
			box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
			z-index: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0px 120px;
			position: relative;
			
			.logo {
				display: flex;
				align-items: center;
			}
			
			.user-info {
				width: 104px;
				
				.phone {
					font-size: 14px;
					color: #656565;
					text-align: end;
				}
				
				.nickname {
					margin-top: 6px;
					display: inline-flex;
					align-items: center;
					
					img {
						margin-right: 10px;
						width: 30px;
						height: 30px;
					}
					
					span {
						font-size: 16px;
						color: #333333;
					}
				}
			}
		}
		
		.header {
			height: 130px;
			background-color: #FFFFFF;
			padding: 0px 120px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			
			.title {
				font-size: 20px;
				color: #4D4D4D;
				font-weight: 600;
			}
			
			.process {
				margin-top: 17px;
				display: inline-flex;
				justify-content: space-between;
				align-items: center;
				font-size: 18px;
				color: #4D4D4D;
				
				.timer {
					display: inline-flex;
					justify-items: center;
					align-items: center;
					
					img {
						width: 22px;
						height: 22px;
						margin-right: 8px;
					}
					
					.count {
						padding: 0px 4px;
						color: #EB4336;
					}
				}
			}
		}
		
		.content {
			background-color: #FFFFFF;
			margin-top: 20px;
			padding: 0px 120px;
			font-size: 18px;
			overflow: scroll;
			min-height: calc(99% - 354px);
			
			.title {
				margin-top: 30px;
				color: #4D4D4D;
				font-weight: 600;
			}
			
			.detail {
				margin-top: 30px;
				margin-bottom: 6px;
				
				.row {
					margin-bottom: 24px;
					font-size: 16px;
					display: flex;
					
					.label {
						color: #717171;
						width: 100px;
					}
					
					.product {
						margin-left: 10px;
						width: 100%;
						background-color: #FAFAFA;
						border-radius: 4px;
						border: 1px solid #E6E6E6;
						padding: 24px 24px 0px 24px;
						
						.item {
							margin-bottom: 24px;
							display: flex;
							
							.info {
								margin-left: 16px;
								display: grid;
								grid-template-rows: auto 27px 20px;
								
								.name {
									font-size: 18px;
									color: #323232;
								}
								
								.props {
									font-size: 14px;
									color: #9A9A9A;
								}
								
								.quantity {
									font-size: 16px;
									color: #656565;
								}
							}
						}
					}
				}
			}
			
			.spliter {
				border-top: 1px solid #DBDBDB;
				text-align: end;
				text-align: right;
				padding: 30px 0px;
			}
			
		}
		
		.footer {
			height: 105px;
			text-align: center;
			
			.icp {
				margin-top: 30px;
				display: inline-flex;
				
				a {
					text-decoration: none;
					display: inline-flex;
				}
			}
		}
	}
</style>
