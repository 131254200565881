import Api from 'axios'
import StorageUtils from './storage'
import { codePrompts } from './codePrompts.js'
/* 加载国际化实例 */
import i18n from '../lang/index'

import Router from '@/router'

Api.defaults.baseURL = process.env.VUE_APP_AUTH || ''; // 根据env配置引入当前环境的请求域名
Api.defaults.timeout = 10000000000;// 请求过期时间

let Qs = require('querystring');

// request拦截
Api.interceptors.request.use(
    config => {
        config.headers['Authorization'] = StorageUtils.getToken() || '';
		/* 用于接口调用上报当前是什么端，区分移动端IOS/Android/PC */
		config.headers['client'] = 'B2BP';
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// response拦截
Api.interceptors.response.use(
    config => {
        // 正常的, 科学的返回
        if (config.data.code) {
            // 如果存在指定code的话, 显示为弹出对话框确认消息
			if(codePrompts(config.data.code)){
			}else {
				if(config.data.code == '400'){/* 400服务端特殊错误 */
					return Promise.reject( config.data.message);
				} else {/* 服务端验证错误，从错误码字典比对 */
					return Promise.reject(i18n.t('apiCodes')[config.data.code] || i18n.t('common.networkError'));
				}
			}
        }
        return config;
    },
    error => {
		console.log(error)
        // 如果没有response的话, 说明接口调用不成功
        if (!error.response) {
            return Promise.reject(i18n.t('common.networkError'));
        }
        // 根据不同的返回HTTP状态码, 执行不同的逻辑
        // 登录过期/token失效
        if (error.response.status === 401) {
            // Token失效, 删除现有的token
            StorageUtils.deleteToken();
			StorageUtils.clearUserInfo();
            // 返回登录页面
			let router = {
				path: '/auth?e='
			}
			Router.push(router)
            return Promise.reject(i18n.t('common.authUserError'));
        }
        // 除了401 2xx 状态码, 均报此错误
        return Promise.reject(i18n.t('common.systemError'));
    }
);

export default Api;