import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elementJaLocale from 'element-ui/lib/locale/lang/ja'
import locale from 'element-ui/lib/locale'
import zhLocale from './zh/index'
import jaLocale from './ja/index'

Vue.use(VueI18n)

/* 引入本地国际化文件 */
const messages = {
	zh: {
		...zhLocale,
		...elementZhLocale
	},
	ja: {
		...jaLocale,
		...elementJaLocale
	}
}

/* 创建国际化实例 */
const i18n = new VueI18n({
	locale: 'zh',/* 设定初始化国际化 */
	messages
})
locale.i18n((key, value) => i18n.t(key, value))
export default i18n