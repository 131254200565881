/* 用于存储客户端到localStorage */
import { validateNull } from '@/utils/validate.js'

/* 定义存储键类型 */
const types = {
	TOKEN: 'token',
	UIF: 'uif'
}

/* 存储对象调用 */
export const setStorage = (params = {}) => {
	let {
		name,
		content,
		type
	} = params
	let obj = {
		dataType: typeof (content),
		content: content,
		type: type,
		datatime: new Date().getTime()
	}
	if (type) {
		window.sessionStorage.setItem(name, JSON.stringify(obj))
	} else {
		window.localStorage.setItem(name, JSON.stringify(obj))
	}
}

/* 获取存储对象 */
export const getStorage = (params = {}) => {
	let content 
	let obj = getStorageObj(params)	
	if(validateNull(obj)) return
	if (obj.dataType === 'string') {
		content = obj.content
	} else if (obj.dataType === 'number') {
		content = Number(obj.content)
	} else if (obj.dataType === 'boolean') {
		content = JSON.parse(obj.content)
	} else if (obj.dataType === 'object') {
		content = obj.content
	}
	return content
}

/* 获取存储的完整对象 */
export const getStorageObj = (params = {}) => {
	let {
		name
	} = params
	let obj = {}	
	if (validateNull(obj)) {
		obj = window.localStorage.getItem(name)
		if (validateNull(obj)) return null
	}
	if (validateNull(obj)) {
		obj = window.sessionStorage.getItem(name)
		if (validateNull(obj)) return null
	}
		
	try {
		obj = JSON.parse(obj)
	} catch (e) {
		return obj
	}
	return obj
}

/* 删除存储对象调用 */
export const removeStorage = (params = {}) => {
	let {
		name,
		type
	} = params
	if (type) {
		window.sessionStorage.removeItem(name)
	} else {
		window.localStorage.removeItem(name)
	}
}

/* 获取所有存储键值信息 */
export const getAllStorage = (params = {}) => {
	let list = []
	let {
		type
	} = params
	if (type) {
		for (let i = 0; i < window.sessionStorage.length; i++) {
			list.push({
			name: window.sessionStorage.key(i),
			content: getStorage({
					name: window.sessionStorage.key(i),
					type: 'session'
				})
			})
		}
	} else {
		for (let i = 0; i < window.localStorage.length; i++) {
			list.push({
			name: window.localStorage.key(i),
			content: getStorage({
					name: window.localStorage.key(i)
				})
			})
		}
	}
	return list
}

/* 删除对应存储键值 */
export const clearStorage = (params = {}) => {
	let { type } = params
	if (type) {
		window.sessionStorage.clear()
	} else {
		window.localStorage.clear()
	}
}

export default {
	/* 存储TOKEN */
	setToken(val) {
		setStorage({name: types.TOKEN, content: val})
	},
	/* 获取TOKEN */
	getToken() {
	  return getStorage({name: types.TOKEN})
	},
	/* 获取TOKEN登录的时间 */
	getTokenTime() {
		let obj = getStorageObj({name: types.TOKEN})
		return obj.datatime
	},
	/* 删除TOKEN */
	deleteToken(){
		clearStorage({name: types.TOKEN})
	},
	/* 存储用户信息 */
	setUserInfo(val) {
		setStorage({name: types.UIF, content: val})
	},
	/* 获取存储的用户信息 */
	getUserInfo() {
		return getStorage({name: types.UIF})
	},
	/* 删除用户信息 */
	clearUserInfo() {
		clearStorage({name: types.UIF})
	}
}
