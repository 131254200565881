import Router from '@/router'
import Vue from 'vue'
/* 加载国际化实例 */
import i18n from '../lang/index'

let vue = new Vue();

/* 此文件用于校验需要弹出信息框的错误码，非弹出业务错误码不要填写 */
let arr = ['63019'];
let map = {
	'30113':'/auth',
}

/* 弹出框唯一性标志 */
let modelShow = false;
export function codePrompts(code) {
	if(arr.includes(code)){
		if(!modelShow){
			modelShow = true;
			vue.$alert(i18n.t('apiCodes')[code], i18n.t('common.prompting'), {
				confirmButtonText: i18n.t('common.knows'),
				callback: action => {
					modelShow = false;
					vue.$destroy();
					let router = {
						path: map[code],
					}
					Router.push(router)
				}
			});
		}
	}
	return arr.includes(code)
}