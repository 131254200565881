<template>
	<div class="app">
		<router-view/>
	</div>
</template>

<script>
	export default {
	  name: 'app'
	}
</script>

<style lang="scss" scoped>
	.app {
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-color: transparent;
	    overflow: hidden;
	}
</style>