<template>
	<div class="full-height auth-wrapper">
		<div class="content-wrapper">
			<div class="content">
				<div class="title">
					<img src="../assets/auth-logo.png"/>
				</div>
				<div class="scan" v-if="!scaned">
					<div class="block" v-loading="loading" @mouseover="showTip" @mouseleave="hideTip">
						<div class="code">
							<vue-qr
								:text="'B2B@'+ code"
								:margin="0" colorLight="#fff" :size="160">
							</vue-qr>
							<span v-show="expired" class="expire-actions">
								<span>
									<span style="font-size: 13px;">二维码已失效</span>
									</br>
									</br>
									<el-button type="primary" size="mini" @click="refreshCode()">刷新</el-button>
								</span>
							</span>
						</div>
						<transition name="el-zoom-in-center" :appear="true">
							<div class="demo" v-show="tipShow">
								<img src="../assets/demo.png"/>
							</div>
						</transition>
					</div>
					<div class="tips">
						使用 <a href="https://www.obawang.com/appDownload" target="_blank">偶爸app</a> 网银支付扫码授权登录
					</div>
				</div>
				<div class="confirm" v-loading="loading" v-else>
					<transition name="el-zoom-in-center" :appear="true">
						<img v-show="true" class="avatar" :src="avatar?`${imgUrl}${avatar}`:require('@/assets/user.png')"/>
					</transition>
					<div class="result">扫码成功</div>
					<div class="tips">请在偶爸app上确认授权登录</div>
					<div class="btn" @click="switchAccount">
						切换账号
					</div>
				</div>
			</div>
		</div>
		<div class="foot-wrapper">
			<div class="icp">
				<a 	target="_blank" 
					href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050802011267">
					<img src="../assets/beian.d0289dc0.png"/>
					<span style="margin: 0px 0px 0px 5px; color: #000000;">苏公网安备 32050802011267号</span>
				</a>
				<span style="margin-left: 40px;">增值电信业务经营许可证：苏B2-20200366 | 苏ICP备17011521号-1 | 营业执照</span>
			</div>
			<div>Copyright © 2019-现在 obawang.com 版权所有</div>
		</div>
	</div>
</template>

<script>
	import StorageUtils from '@/utils/storage'
	import vueQr from 'vue-qr'
	import {b2bCodeGen,pollingChkB2BCode,clearB2BCode} from '@/request/base'
	
	export default {
		data() {
			return {
				loading: false,
				imgUrl: process.env.VUE_APP_IMG_SHOW_URL, // 图片显示前缀域名
				tipShow: true,
				/* 判定是否过期 */
				expired: false,
				/* 判定是否扫码 */
				scaned: false,
				/* 用户头像 */
				avatar: null,
				/* 临时CODE */
				code: '',
				timer: null /* 校验有效性计时器 */
			}
		},
		beforeRouteLeave(to,from,next) {
			if(this.timer) {
				window.clearInterval(this.timer)
			}
			next()
		},
		created() {
			//清除预先授权数据
			StorageUtils.deleteToken();
			StorageUtils.clearUserInfo();
			
			let source = this.$route.query.e
			if(source != undefined) {
				this.$message.warning('二维码授权会话已过期，为了您的账户安全请进行重新扫码！')
			}
			this.generateCode()
			setTimeout(() => {
				this.tipShow = false
			}, 5000);
		},
		methods: {
			showTip() {
				this.tipShow = true
			},
			hideTip() {
				this.tipShow = false
			},
			/* 生成CODE */
			generateCode() {
				this.loading = true
				b2bCodeGen().then(response => {
					this.code = response.data.data
					//清除预先授权数据
					StorageUtils.deleteToken();
					StorageUtils.clearUserInfo();
					
					this.expired = false
					this.scaned = false
					/* 设计计时器 */
					let _timer = window.setInterval(() => {						
						pollingChkB2BCode(this.code).then(response => {
							let _chk = response.data.data
							if(_chk.isExpired) {
								/* 已过期情况 */
								this.expired = true
								this.scaned = false
								window.clearInterval(_timer)
							} else {
								/* 是否已经扫码 */
								if(_chk.isScan) {
									this.scaned = true
									this.avatar = _chk.avatar
								}
								
								if(_chk.isAuth) {
									/* 未过期已扫码并且已授权 */
									//清除预先授权数据
									StorageUtils.deleteToken();
									StorageUtils.clearUserInfo();
									
									let uif = {
										orderNos: _chk.orderNos,
										orderUserId: _chk.orderUserId,
										userId: _chk.userId,
										goodsType:_chk.goodsType,
										avatar: _chk.avatar,
										nickname: _chk.nickname,
										phone: _chk.phone
									}
									StorageUtils.setUserInfo(uif)
									StorageUtils.setToken(_chk.token)
									let router = {
									    path: '/pay'
									}
									this.$router.push(router)
									window.clearInterval(_timer)
								}
							}
						})
					}, 1000)
					this.timer = _timer
					this.loading = false
				}).catch(errorMessage => {
					this.loading = false
				})
			},
			/* 刷新CODE */
			refreshCode() {
				//清除已有的定时器
				if(this.timer) {
					window.clearInterval(this.timer)
				}
				this.generateCode()
			},
			switchAccount() {
				clearB2BCode(this.code).then(response => {
					this.scaned = false
					this.expired = false
					if(this.timer) {
						window.clearInterval(this.timer)
					}
					this.generateCode()
				}).catch(errorMessage => {
					this.$message.error('切换账户失败！')
				})
				
			}
		},
		components:{
			'vue-qr':vueQr
		}
	}
</script>

<style scoped lang="scss">
	.auth-wrapper {
		background-color: #ada9a9;
		background: url("~@/assets/bg.png") repeat left top;
		opacity: 0.8;
		
		.content-wrapper {
			min-height: calc(100% - 75px);
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: auto;
			
			.content {
				width: 430px;
				min-width: 430px;
				height: 470px;
				background-color: #fff;
				color: #333;
				border-radius: 14px;
				box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.08);
				display: flex;
				flex-direction: column;
				
				.title {
					height: 136px;
					border-bottom: 1px solid #ECECEC;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				
				.scan {
					flex: 1;
					display: flex;
					flex-direction: column;
					cursor: pointer;
					
					.block {
						flex: 1;
						display: inline-flex;
						justify-content: center;
						align-items: center;
						
						.code {							
							border: 1px dashed #ada9a9;
							padding: 8px 8px 3px 8px;
							position: relative;
							
							.avatar {
								width: 160px;
								height: 160px;
								display: table-cell;
								vertical-align: middle;
								text-align: center;
								
								img {
									width: 92%;
									height: 90%;
								}
							}
							
							.expire-actions {
							    position: absolute;
							    width: 100%;
							    height: 100%;
							    left: 0;
							    top: 0;
							    cursor: default;
							    text-align: center;
							    color: #fff;
							    opacity: 1;
							    font-size: 20px;
							    background-color: rgba(0,0,0,.75);
							    transition: opacity .3s;
								display: flex;
								justify-content: space-around;
								align-items: center;
							}
							
							.expire-actions span {
							    cursor: pointer;
							}
							
							.expire-actions:hover span {
							    display: inline-block;
							}
						}
						
						.demo {
							margin-left: 26px;
							border: 1px dashed #ada9a9;
													
							img {
								width: 100%;
							}
						}
					}
					
					.tips {
						height: 67px;
						font-size: 18px;
						text-align: center;
						
						a {
							color: #FC8C26;
							text-decoration: none;
						}
					}
				}
				
				.confirm {
					display: flex;
					flex-direction: column;
					align-items: center;
					
					.avatar {
						margin-top: 28px;
						width: 132px;
						height: 132px;
					}
					
					.result {
						font-size: 20px;
						font-weight: 600;
						margin-top: 22px;
						color: #4D4D4D;
					}
					
					.tips {
						font-size: 18px;
						margin-top: 10px;
						color: #676767;
					}
					
					.btn {
						margin-top: 22px;
						font-size: 16px;
						color: #FC8C26;
						border: 1px solid #FC8C26;
						height: 36px;
						line-height: 36px;
						border-radius: 4px;
						padding: 0px 20px 0px 20px;
						cursor: pointer;
					}
				}
			}
		}
		
		.foot-wrapper {
			height: 75px;
			text-align: center;
			
			.icp {
				display: inline-flex;
				
				a {
					text-decoration: none;
					display: inline-flex;
				}
			}
		}
	}	
</style>
