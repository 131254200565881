import Vue from 'vue'
import VueRouter from 'vue-router'
/* 加载客户端存储类 */
import StorageUtils from '@/utils/storage'
/* 加载国际化实例 */
import i18n from '../lang/index'

/* 加载所有的页面组件 */
import Index from '@/views/Index.vue'
import Pay from '@/views/pay/Index.vue'
import PayDetail from '@/views/pay/Detail.vue'
import PayResult from '@/views/pay/Result.vue'

/* 通用组件 */
import NoPage from 'src/components/404.vue'
import Auth from '@/views/Auth.vue'

/* 切换路由验证TOKEN以及路由权限*/
const validateToken = (to, form, next) => {
    // 如果没有token的话, 跳转回登录页面
	if (!StorageUtils.getToken()) {
        next('/auth?e=');
        return;
    } else {
		//TODO: 需要验证当前登录者对应路由的权限，否则404
		//console.log(StorageUtils.getUserInfo())
	}
    next();
};  

// 解决两次访问相同路由地址报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: i18n.t('routers.pay'),
		component: Index,
		redirect: '/auth',
		children: [{
			path: 'auth',
			name: i18n.t('routers.auth'),
			component: Auth
		},{
			path: 'pay',
			name: i18n.t('routers.pay_index'),
			beforeEnter: validateToken,
			component: Pay,
			redirect: '/pay/details',
			children: [{
				path: 'details',
				name: i18n.t('pay_detail'),
				component: PayDetail
			},{
				path: 'result/:status?/:tradeNo?/:orderNo?',
				name: i18n.t('routers.pay_result'),
				component: PayResult
			}]
		}]
	},{
		path: '*',
		name: '404',
		component: NoPage 
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
